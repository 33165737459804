import { graphql, HeadProps } from 'gatsby';
import React from 'react';
import { LatestNewsCategoryPageQueryQuery } from '../../graphql-types';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import LatestNewsView from './views/latest-news-view';

export interface LatestNewsPageProps {
  data: LatestNewsCategoryPageQueryQuery;
  pageContext: any;
}

export function Head(props: HeadProps<any, { tagName: string; tagId: string; tagSlug: string }>) {
  const { pageContext } = props;
  return (
    <SEO
      pageUrl={props.location.pathname}
      seo={{ title: `${pageContext.tagName} Archives - Moving Loop` }}
    />
  );
}

const LatestNewsPage = (props: LatestNewsPageProps) => {
  const { data, pageContext } = props;
  const parentUrl = `/category/${pageContext.tagSlug}`;

  return <LatestNewsView data={data?.CraftGQL} parentUrl={parentUrl} pageContext={pageContext} />;
};

export const query = graphql`
  query LatestNewsCategoryPageQuery(
    $skip: Int!
    $limit: Int!
    $slug: String!
    $tagId: [CraftGQL_QueryArgument]
  ) {
    CraftGQL {
      tags(site: "movingLoop") {
        ... on CraftGQL_newsTags_Tag {
          id
          title
          slug
        }
      }
      entry(slug: [$slug], site: "movingLoop") {
        ... on CraftGQL_mlLatestNewTemplate_mlLatestNewTemplate_Entry {
          heroBackgroundImage {
            url
          }
          heroIcon {
            url
          }
          heroBackgroundColor
          heroTitle
          seo {
            title
            description
          }
        }
      }
      entries(
        inReverse: true
        orderBy: "postDate"
        limit: $limit
        offset: $skip
        newsTags: $tagId
        section: "mlLatestNews"
        site: "movingLoop"
      ) {
        ... on CraftGQL_mlLatestNews_default_Entry {
          id
          slug
          heroBackgroundImage {
            url
          }
          title
          postDate
          newsTags {
            ... on CraftGQL_newsTags_Tag {
              id
              title
            }
          }
        }
      }
    }
  }
`;

LatestNewsPage.Layout = Layout;
export default LatestNewsPage;
